document.addEventListener('click', e => {
    if (!e.target.matches('[data-behaviour="clear-search"]')) return false;

    const search = e.target.closest('[data-target="search"]');
    
    search.querySelector('[data-target="search-input"]').value = '';
});

const submitSearch = (searchbar) => {
    const query = searchbar.querySelector('[data-target="search-input"]').value;

    if (query.length >= 2) window.location = '/?s=' + encodeURI(query);
}

document.addEventListener('click', e => {
    console.log(e.target);
    if (!e.target.matches('[data-behaviour="search-submit"]')) return false;
    console.log(e.target);

    const search = e.target.closest('[data-behaviour="search"]');

    submitSearch(search);
});

document.addEventListener('keypress', e => {
    if (e.key !== "Enter") return false;

    if (document.activeElement.matches('[data-target="search-input"]')) submitSearch(document.activeElement.closest('[data-behaviour="search"]'));
    
    return false;
});